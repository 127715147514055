import { onPageLoad } from './helpers/index.js';

import icon from './components/icon.js';

import secondaryNav from './components/secondary-nav.js';
import accordion from './components/accordion.js';
import dropdown from './components/dropdown.js';
import validatedForm from './components/validated-form.js';
import ProductModule from './components/product-module.js';
import Slider from './components/slider.js';

onPageLoad(async () => {
	window.StandardUI = {};

	// single-init components
	icon.init();

	// dynamic components
	const init = () => {
		secondaryNav.init();
		accordion.init();
		dropdown.init();
		validatedForm.init();
		ProductModule.init();
		Slider.init();
	};

	// watch document for changes
	const observer = new MutationObserver(init);
	observer.observe(document, {
		childList: true,
		subtree: true
	});
});
