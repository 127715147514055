/**
 * This script handles a very simplistic validation logic that augments the HTML5 native validation scheme.
 * When the [data-validate] attribute is set on a form, the submit button click event is hijacked and failed submissions
 * will add the [data-validattion-dirty] to the form root (which in turn triggers some :invalid css styles to be applied).
 */

export class ValidatedForm {
	root: HTMLFormElement;

	constructor(root: HTMLFormElement) {
		this.root = root;
		this.root.removeAttribute('data-validate');
		this.root.dataset.validationActive = '';
		this.bindEvents();
	}

	bindEvents = () => {
		// find and hijack submit buttons
		this.root.querySelectorAll('[type="submit"]').forEach((button) => {
			button.addEventListener('click', () => {
				this.root.setAttribute('data-validation-dirty', '');
			});
		});

		// reset state on successful submit
		this.root.addEventListener('submit', () => {
			this.root.removeAttribute('data-validation-dirty');
		});
	};
}

export const init = () => {
	const forms = Array.from(document.querySelectorAll<HTMLFormElement>('form[data-validate]'));
	const instances: ValidatedForm[] = [];

	for (const root of forms) {
		instances.push(new ValidatedForm(root));
	}

	return instances;
};

export default {
	init
};
