import { Instance as PopperInstance, createPopper, preventOverflow, flip } from '@popperjs/core';

export type DropdownOption = {
	text: string;
	value: string;
	content: string;
	disabled: boolean;
};

export type DropdownOptionGroup = {
	label: string;
	options: DropdownOption[];
};

export class Dropdown {
	root: HTMLElement;
	select: HTMLSelectElement;
	multiple: boolean;
	searchInput: HTMLInputElement;
	options: (DropdownOption | DropdownOptionGroup)[] = [];
	dropdown: HTMLElement;
	dropdownOptions: HTMLElement;
	popper: PopperInstance;

	keyboardFocus = -1;

	constructor(root: HTMLElement) {
		this.root = root;

		const select = root.querySelector('select');
		if (!select) throw new Error('[ui:dropdown] Dropdown element has no <select> tag.');
		this.select = select;
		this.select.tabIndex = -1;
		this.multiple = select.multiple;

		// visible input for searching options
		this.searchInput = document.createElement('input');
		this.searchInput.type = 'text';
		// this.searchInput.id = select.id;
		this.searchInput.name = select.name.replace(/[[\]]/g, '-') + '--q';
		this.searchInput.placeholder = root.dataset.dropdownPlaceholder || '';

		// parse <option> tags from select
		this.parseOptions();

		// build schema for popup with options
		this.dropdown = document.createElement('div');
		this.dropdown.className = 'dropdown-popup';
		this.dropdown.setAttribute('data-hide', '');

		this.dropdownOptions = document.createElement('div');
		this.dropdownOptions.className = 'dropdown-options';
		this.dropdown.appendChild(this.dropdownOptions);

		this.updateOptions();
		this.updateState();

		// set up a mutation observer on the original select box to keep track of external option changes
		const observer = new MutationObserver(() => {
			this.parseOptions();
			this.updateOptions();
			this.updateState();
		});
		observer.observe(this.select, { childList: true, subtree: true, attributes: true, characterData: true });

		// switch select with input set and wrap input controls into a caret helper
		const wrapper = document.createElement('div');
		wrapper.className = 'dropdown-field-wrapper';
		this.select.insertAdjacentElement('afterend', wrapper);
		wrapper.appendChild(this.select);
		wrapper.appendChild(this.searchInput);
		wrapper.appendChild(this.dropdown);

		// register popup & related events
		this.popper = createPopper(this.searchInput, this.dropdown, {
			modifiers: [
				preventOverflow,
				flip,
				{ name: 'offset', options: { offset: [0, 5] } }
			]
		});

		this.root.addEventListener('focusin', this.show);
		this.root.addEventListener('focusout', this.hide);
		this.select.addEventListener('focus', () => {
			// when the focus is set by the browser's validator on the select box directly,
			// we pass it back onto the search input
			this.searchInput.focus();
		});
		this.select.addEventListener('change', () => {
			this.updateState();
			this.updateOptions();
		});
		this.searchInput.addEventListener('click', this.show);
		this.searchInput.addEventListener('input', this.updateOptions);
		this.searchInput.addEventListener('keydown', this.onInputKeydown);
		this.dropdown.addEventListener('mousedown', this.onDropdownClick);
	}

	show = () => {
		this.dropdown.removeAttribute('data-hide');
		this.popper.update();
	};

	hide = async () => {
		this.dropdown.setAttribute('data-hide', '');
	};

	parseOptions = () => {
		this.options = [];
		for (const child of Array.from(this.select.children)) {
			if (child.tagName.toLocaleLowerCase() === 'option') {
				const opt = child as HTMLOptionElement;
				this.options.push({
					text: opt.innerText.trim(),
					value: opt.value,
					content: opt.dataset.content || opt.innerText.trim(),
					disabled: opt.disabled
				});
			} else if (child.tagName.toLocaleLowerCase() === 'optgroup') {
				const optgroup = child as HTMLOptGroupElement;
				const group: DropdownOptionGroup = { label: optgroup.label, options: [] };
				optgroup.querySelectorAll('option').forEach(opt => {
					group.options.push({
						text: opt.innerText.trim(),
						value: opt.value,
						content: opt.dataset.content || opt.innerText.trim(),
						disabled: opt.disabled
					});
				});
				this.options.push(group);
			}
		}
	};

	updateOptions = () => {
		const query = this.searchInput.value.toLocaleLowerCase();
		const filteredOptions = query ? [] : this.options;

		if (query) {
			// comparison helper
			const compare = (opt: DropdownOption) => {
				return opt.text.toLocaleLowerCase().includes(query);
			};

			// filter nested options
			for (const opt of this.options) {
				if ('options' in opt) {
					const group: DropdownOptionGroup = { label: opt.label, options: [] };
					for (const o of opt.options) {
						if (compare(o)) group.options.push(o);
					}
					if (group.options.length > 0) filteredOptions.push(group);
				} else {
					if (compare(opt)) filteredOptions.push(opt);
				}
			}
		}

		while (this.dropdownOptions.lastChild) this.dropdownOptions.removeChild(this.dropdownOptions.lastChild);

		for (const opt of filteredOptions) {
			if ('options' in opt) {
				const label = document.createElement('div');
				label.className = 'optgroup-label';
				label.innerText = opt.label;
				this.dropdownOptions.appendChild(label);

				for (const o of opt.options) {
					this.dropdownOptions.appendChild(this.createOption(o));
				}
			} else {
				this.dropdownOptions.appendChild(this.createOption(opt));
			}
		}

		// append empty-state placeholder
		if (this.dropdownOptions.children.length === 0) {
			const empty = document.createElement('div');
			empty.className = 'dropdown-options-empty';
			empty.innerText = this.root.dataset.dropdownEmptyText || 'No options found.';
			this.dropdownOptions.appendChild(empty);
		}

		if (this.popper) this.popper.update();
	};

	createOption = (opt: DropdownOption) => {
		const option = document.createElement('button');
		option.className = 'dropdown-option';

		if (this.multiple) {
			const activeOpt = this.select.querySelector<HTMLOptionElement>(`option[value="${opt.value}"]`);
			if (activeOpt?.selected) option.classList.add('selected');
		} else {
			if (opt.value && opt.value === this.select.value) option.classList.add('selected');
		}

		option.value = opt.value;
		if (opt.disabled) option.disabled = true;
		option.innerHTML = opt.content;
		option.tabIndex = -1;
		return option;
	};

	onInputKeydown = (evt: KeyboardEvent) => {
		const opts = Array.from(this.dropdownOptions.querySelectorAll<HTMLButtonElement>('button:not(:disabled)'));
		if (evt.key === 'ArrowDown') {
			this.show();
			this.keyboardFocus++;
			if (this.keyboardFocus >= opts.length) this.keyboardFocus = 0;
		}
		else if (evt.key === 'ArrowUp') {
			this.show();
			this.keyboardFocus--;
			if (this.keyboardFocus < 0) this.keyboardFocus = opts.length - 1;
		}
		else if (evt.key === 'Enter') {
			if (!this.multiple) this.keyboardFocus = -1;
			const currentFocus = this.dropdownOptions.querySelector<HTMLButtonElement>('button.keyboard-focused');
			const target = currentFocus || (opts.length === 1 ? opts[0] : null);
			if (target && !target.disabled) this.setValue(target.value);
		}
		else if (evt.key === 'Escape') {
			this.keyboardFocus = -1;
			this.hide();
		}

		const currentFocus = this.dropdownOptions.querySelector('button.keyboard-focused');
		if (currentFocus) currentFocus.classList.remove('keyboard-focused');

		// scroll dropdown item into view
		if (this.keyboardFocus >= 0 && this.keyboardFocus < opts.length) {
			opts[this.keyboardFocus].classList.add('keyboard-focused');
			setTimeout(() => {
				opts[this.keyboardFocus].scrollIntoView({ block: 'nearest' });
			});
		}
	};

	onDropdownClick = (evt: MouseEvent) => {
		const button = evt.target as HTMLButtonElement;
		if (button.classList.contains('dropdown-option') && !button.disabled) {
			this.setValue(button.value);
			evt.preventDefault();
		}
	};

	setValue = (value: string) => {
		this.select.click();

		if (this.multiple) {
			const opt = this.select.querySelector<HTMLOptionElement>(`option[value="${value}"]`);
			if (opt) opt.selected = !opt.selected;
		} else {
			this.select.value = value;
		}

		this.select.dispatchEvent(new Event('change'));
		this.updateState();

		if (!this.multiple) {
			this.searchInput.value = '';
			this.updateOptions();
			this.hide();
		}
	};

	getOptionText = (value: string) => {
		for (const x of this.options) {
			if ('options' in x) {
				for (const y of x.options) {
					if (y.value === value) return y.text;
				}
			} else {
				if (x.value === value) return x.text;
			}
		}

		return null;
	};

	updateState = () => {
		if (this.select.value) this.root.setAttribute('data-dropdown-filled', '');
		else this.root.removeAttribute('data-dropdown-filled');

		// set placeholder text
		if (this.multiple && this.select.selectedOptions.length > 0) {
			const optTexts = Array.from(this.select.selectedOptions).map(opt => this.getOptionText(opt.value));
			this.searchInput.placeholder = optTexts.join(', ');
		} else if (!this.multiple && this.select.value) {
			const text = this.getOptionText(this.select.value);
			this.searchInput.placeholder = text || this.select.value;
		} else {
			this.searchInput.placeholder = this.root.dataset.dropdownPlaceholder || '';
		}
	};
}

export const init = () => {
	const dropdowns = Array.from(document.querySelectorAll<HTMLElement>('[data-dropdown]'));
	if (!window.StandardUI.dropdown) window.StandardUI.dropdown = { init, instances: [] };

	for (const root of dropdowns) {
		if (!window.StandardUI.dropdown.instances.find(x => x.root === root)) {
			const dropdown = new Dropdown(root);
			window.StandardUI.dropdown.instances.push(dropdown);
		}
	}
};

export default {
	init
};
