export class ProductModule {
	root: HTMLElement;
	cards: HTMLElement[];
	expandButton: HTMLButtonElement;

	maxRows: number;
	columns: number;

	collapsible = false;
	collapsed = true;

	constructor(root: HTMLElement) {
		this.root = root;
		this.root.dataset.autocollapse = '';
		if (this.collapsed) this.root.dataset.collapsed = '';

		this.cards = Array.from(this.root.querySelectorAll('.product-module-card'));
		this.columns = 0;

		let rows = parseInt(this.root.dataset.productModuleGridAutocollapse || '');
		if (isNaN(rows)) rows = 2;
		this.maxRows = rows;

		// insert button for expanding the list
		const buttonGroup = document.createElement('div');
		buttonGroup.className = 'collapse-toggle';

		this.expandButton = document.createElement('button');
		this.expandButton.innerHTML = this.root.dataset.autocollapseText || 'Show More';
		this.expandButton.className = 'button';

		buttonGroup.appendChild(this.expandButton);
		this.root.insertAdjacentElement('afterend', buttonGroup);

		this.setupAutocollapse();
	}

	setupAutocollapse = () => {
		// watch for global grid resize events
		const resizeObserver = new ResizeObserver(this.recalculateSizes);
		resizeObserver.observe(this.root);

		// watch the first few cards for resize
		const cardsToWatch = this.cards.slice(0, this.maxRows * 3);
		for (const card of cardsToWatch) {
			resizeObserver.observe(card);
		}

		// initialize state
		this.updateColumnCount();
		this.recalculateSizes();

		// bind events
		window.addEventListener('resize', () => {
			this.updateColumnCount();
			this.recalculateSizes();
		});
		this.expandButton.addEventListener('click', this.toggleCollapseState);
	};

	update = () => {
		this.updateColumnCount();
		this.recalculateSizes();
	};

	/**
	 * Gets the computed grid template sizes & check the number of rows.
	 */
	updateColumnCount = () => {
		const gridTemplateColumns = getComputedStyle(this.root).gridTemplateColumns;
		this.columns = gridTemplateColumns.split(' ').length;
	};

	/**
	 * Updates the size of the sizer overlay based on max rows & current column count and content.
	 * This function also determines whether the current state should be collapsible or not, and
	 * updates the DOM with that state if necessary.
	 */
	recalculateSizes = () => {
		const lastIndex = (this.maxRows * this.columns) - 1;

		const hasEnoughCards = this.cards.length > lastIndex + 1;

		// update collapsible state
		if (!hasEnoughCards && this.collapsible) {
			delete this.root.dataset.collapsible;
			this.collapsible = false;
		} else if (hasEnoughCards && !this.collapsible) {
			this.root.dataset.collapsible = '';
			this.collapsible = true;
		}

		// update max height
		if (hasEnoughCards) {
			const lastBoundaryCard = this.cards[lastIndex];
			const lastCard = this.cards[this.cards.length - 1];

			const referenceBox = this.root.getBoundingClientRect();
			const box = (this.collapsed ? lastBoundaryCard : lastCard).getBoundingClientRect();
			const newHeight = box.top - referenceBox.top + box.height + 1;
			this.root.dataset.maxHeight = newHeight + 'px';
			this.root.style.maxHeight = newHeight + 'px';
		} else {
			delete this.root.dataset.maxHeight;
			this.root.style.maxHeight = 'none';
		}
	};

	/**
	 * Toggles the collapse state and updates size calculations.
	 */
	toggleCollapseState = () => {
		this.collapsed = !this.collapsed;
		this.update();
		if (this.collapsed) {
			this.expandButton.innerHTML = this.root.dataset.autocollapseText || 'Show More';
			this.expandButton.className = 'button';
		} else {
			this.expandButton.innerHTML = this.root.dataset.autocollapseTextHide || '<span data-icon="arrow_up"></span>';
			this.expandButton.className = 'button button-bordered';
		}
	};
}

export const init = () => {
	const productModuleGrids = Array.from(document.querySelectorAll<HTMLElement>('.product-module-grid[data-product-module-grid-autocollapse]'));
	const instances: ProductModule[] = [];

	for (const root of productModuleGrids) {
		if (!('autocollapse' in root.dataset)) instances.push(new ProductModule(root));
	}

	return instances;
};

export default {
	init
};
