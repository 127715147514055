/*
 * Note: We can't import swiper typings cleanly yet because the upstream package doesn't properly link the .d.ts
 * declaration file with the ESM export.
 * See: https://github.com/nolimits4web/swiper/issues/5999
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper';

export class Slider {
	root: HTMLElement;
	swiper: Swiper;

	constructor(root: HTMLElement, opts?: SwiperOptions) {
		this.root = root;
		this.swiper = new Swiper(root, {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			spaceBetween: 0,
			watchSlidesProgress: true,
			breakpoints: {
				720: {
					slidesPerView: 2
				},
				1280: {
					slidesPerView: 3
				}
			},
			navigation: {
				nextEl: '.slider-next',
				prevEl: '.slider-prev',
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
			},
			...(opts ?? {})
		});
	}
}

export const init = () => {
	const sliders = Array.from(document.querySelectorAll<HTMLElement>('[data-slider]'));
	if (!window.StandardUI.slider) window.StandardUI.slider = { init, instances: [] };

	for (const root of sliders) {
		if (!window.StandardUI.slider.instances.find(x => x.root === root)) {
			const opts: SwiperOptions = {};

			if (root.dataset.fullWidth || root.dataset.fullWidth === '') {
				opts.breakpoints = {};
			}

			if (root.dataset.autoHeight || root.dataset.autoHeight === '') {
				opts.autoHeight = true;
			}

			if (root.dataset.loop || root.dataset.loop === '') {
				opts.loop = true;
			}

			const slider = new Slider(root, opts);
			window.StandardUI.slider.instances.push(slider);
		}
	}
};

export default {
	init
};
