import { generateId } from '../helpers/index.js';

export class Accordion {
	root: HTMLElement;
	toggles: HTMLElement[];
	content: HTMLElement | null;
	contentObserver: ResizeObserver;
	contentHeight = 'auto';

	id: string;
	group: string | null;
	isOpen: boolean;

	constructor(root: HTMLElement) {
		this.root = root;
		this.content = root.querySelector<HTMLElement>('[data-accordion-content]');
		if (this.content == null) console.warn('[ui:accordion] No content element found.');

		this.id = root.dataset.accordion || generateId('accordion');
		this.group = root.dataset.accordionGroup || null;
		if (root.dataset.accordion !== this.id) root.dataset.accordion = this.id;

		// add all id-irrelevant toggles inside this accordion
		this.toggles = Array.from(root.querySelectorAll<HTMLElement>('[data-accordion-toggle=""]'));

		// add all toggles elsewhere in the document that point specifically to this accordion's id
		document.querySelectorAll<HTMLElement>(`[data-accordion-toggle="${this.id}"]`).forEach(toggle => {
			this.toggles.push(toggle);
		});

		this.contentObserver = new ResizeObserver(this.updateHeight);

		this.isOpen = root.dataset.accordionOpen == 'true' || false;
	}

	open = () => {
		if (this.root.hasAttribute('data-accordion-disabled')) return;

		this.root.dataset.accordionOpen = 'true';
		this.content?.classList.add('accordion-open');
		this.isOpen = true;
		this.updateHeight();

		// close all other accordions from the same group
		if (this.group && window.StandardUI.accordion) {
			const targets = window.StandardUI.accordion.instances.filter(acc => acc.group === this.group && acc.id !== this.id);
			for (const accordion of targets) accordion.close();
		}
	};

	close = () => {
		if (this.root.hasAttribute('data-accordion-disabled')) return;

		delete this.root.dataset.accordionOpen;
		this.content?.classList.remove('accordion-open');
		this.isOpen = false;
		if (this.content) this.content.style.maxHeight = this.content.scrollHeight + 'px';
		this.updateHeight();
	};

	toggle = (evt?: MouseEvent) => {
		if (this.root.dataset.accordionOpen) this.close();
		else this.open();

		if (evt) evt.preventDefault();
	};

	init = () => {
		for (const t of this.toggles) {
			t.addEventListener('click', this.toggle);
		}

		if (this.content) this.contentObserver.observe(this.content);
		window.addEventListener('resize', this.updateHeight);
		this.updateHeight();
	};

	destroy = () => {
		for (const t of this.toggles) {
			t.removeEventListener('click', this.toggle);
		}

		if (this.content) this.contentObserver.disconnect();

		window.removeEventListener('resize', this.updateHeight);
	};

	updateHeight = () => {
		if (!this.content) return;
		this.contentHeight = this.content.scrollHeight + 'px';
		this.content.dataset.height = this.contentHeight;

		if (this.isOpen && this.content.clientHeight === this.content.scrollHeight) {
			// when the accordion is opening and it has reached its target height, we set the max-height to auto
			this.content.style.maxHeight = 'none';
		} else {
			// for all other cases, we wait for the transition to naturally happen
			this.content.style.maxHeight = this.isOpen ? this.contentHeight : '0px';
		}
	};
}

export const init = () => {
	const accordions = Array.from(document.querySelectorAll<HTMLElement>('[data-accordion]'));
	if (!window.StandardUI.accordion) window.StandardUI.accordion = { init, instances: [] };

	for (const root of accordions) {
		if (!window.StandardUI.accordion.instances.find(x => x.root === root)) {
			const accordion = new Accordion(root);
			accordion.init();
			window.StandardUI.accordion.instances.push(accordion);
		}
	}
};

export default {
	init,
};
