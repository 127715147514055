export class SecondaryNav {
	root: HTMLFormElement;

	constructor(root: HTMLFormElement) {
		this.root = root;
		this.root.dataset.secondaryNav = '';
		this.bindEvents();
	}

	bindEvents = () => {
		const navMenu = this.root.querySelector<HTMLElement>('ul.nav-menu');
		if (!navMenu) return;

		const openMobileMenu = () => {
			this.root.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
			this.root.dataset.mobileMenuActive = 'true';
			navMenu.dataset.menuActive = 'true';
		};

		const closeMobileMenu = () => {
			delete this.root.dataset.mobileMenuActive;
			delete navMenu.dataset.menuActive;
		};

		let mobileMenuDisabled = false;
		const updateMobileMenuDisabledState = () => {
			mobileMenuDisabled = document.body.clientWidth >= 1280;
			if (mobileMenuDisabled && !this.root.hasAttribute('data-accordion-disabled')) {
				this.root.dataset.accordionDisabled = '';
			} else if (!mobileMenuDisabled && this.root.hasAttribute('data-accordion-disabled')) {
				delete this.root.dataset.accordionDisabled;
			}
		};

		this.root.querySelectorAll('.page-container > [data-accordion-toggle]').forEach(toggle => {
			toggle.addEventListener('click', () => {
				if (mobileMenuDisabled) return;
				if (navMenu.dataset.menuActive) closeMobileMenu();
				else openMobileMenu();
			});
		});

		window.addEventListener('resize', updateMobileMenuDisabledState);
		updateMobileMenuDisabledState();
	};
}

const _instances: SecondaryNav[] = [];

export const init = () => {
	const navs = Array.from(document.querySelectorAll<HTMLFormElement>('header.secondary-nav'));

	for (const root of navs) {
		if (!('secondaryNav' in root.dataset) && !_instances.find(nav => nav.root === root)) _instances.push(new SecondaryNav(root));
	}
};

export default {
	init
};
